import { iframeResizer } from 'iframe-resizer'

(function (doc, el) {
  var loadEmbeds = () => {
    document.querySelectorAll('.shoutout-embed').forEach((embed, idx) => {
      var wallName = embed.getAttribute('data-wall');
      var widgetId = embed.getAttribute('data-widgetId');
      var wallId = widgetId;
      if (wallName){
        wallId = wallName.replace(/\s/g, '-');
      };
      var loaded = embed.getAttribute('data-loaded');
      if (!loaded) {
        embed.setAttribute('data-loaded', true);
        var i = document.createElement('IFRAME');
        var id = `shoutout-iframe-${wallId}-${idx}`;
        var src = `https://shoutout.io/embed/${wallId}/`;
        i.src = src;
        i.setAttribute('frameborder', '0');
        i.title = `Shoutout.io testimonial embed`;
        i.style.outline = 'none';
        i.setAttribute('scrolling', 'yes');
        i.setAttribute('id', id);
        i.setAttribute('width', '100%');
        i.style.opacity = '0'
        embed.appendChild(i);
        i.onload = function() {
          iframeResizer(
            {
              autoResize: true,
              onInit: () => {
                i.style.opacity = '1';
              }
            },
            `#${id}`
          );
        };
      }
    });
  };
  loadEmbeds();
})(document, 'script');